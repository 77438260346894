/* Ensure the styles apply only to the content that will be converted to PDF */
#pdf-content {
    border: 4px double black;
    padding: 12px;
    margin: 10px;
    font-family: "Arial", sans-serif;
}

#pdf-content header {
    width: 100%;
}

#pdf-content header img {
    width: 100%;
}

#pdf-content table.entete, 
#pdf-content td {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}

#pdf-content .entete img {
    width: 100px;
    height: 100px;
}

#pdf-content .entete .titre {
    font-weight: bold;
    text-align: center;
    font-size: 1.05em;
}

#pdf-content .info {
    width: 100%;
    margin: 16px 0;
    font-family: "Arial Narrow", Arial, sans-serif;
    font-size: 0.9em;
}

#pdf-content .info h3 {
    border-bottom: 1px solid black;
    padding-bottom: 4px;
}

#pdf-content .info h3 span {
    background-color: rgb(31, 30, 30);
    color: white;
    padding: 2px;
}

#pdf-content .item-info-2 {
    width: 46%;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 3%;
    margin-bottom: 13px;
}

#pdf-content .item-info-2 span, 
#pdf-content .item-info-3 span, 
#pdf-content .item-info-4 span {
    font-weight: bold;
}

#pdf-content .item-info-3 {
    width: 29%;
    display: inline-block;
    padding-left: 3%;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 13px;
}

#pdf-content .item-info-4 {
    width: 20%;
    display: inline-block;
    padding-left: 1%;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 13px;
}

#pdf-content footer {
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    text-align: right;
    margin-top: 20px;
}

#pdf-content .connexion {
    width: 48%;
    text-align: center;
    display: inline-block;
}

#pdf-content .imp {
    font-style: italic;
    text-align: right;
    width: 49%;
    display: inline-block;
}

#pdf-content .consigne h3 {
    font-size: 1.01em;
    border-bottom: 1px solid black;
    color: red;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

#pdf-content .consigne li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.6em;
    margin-top: 2px;
}

#pdf-content table, 
#pdf-content th, 
#pdf-content td {
    border: 1px solid black;
}

#pdf-content .english {
    font-style: italic;
    color: brown;
}
